import type {Utms} from "~/models/UTMs/UTMs";
import {keys} from "~/service/Utils";
import {WidgetPartners, Widgets} from "~/models/WidgetPartnerDict";
import type {WidgetUtms} from "~/types/WidgetUtms";

export default function useWidgetUtmsApi() {
    const getWidgetUtmsByKey = async(widgetKey: WidgetPartners | Widgets): Promise<Utms | null> => {
        const widgetPartnerDict: WidgetUtms = await $fetch('/api/widget-utms');
        return widgetPartnerDict?.[widgetKey] ?? null;
    };
    /**@warning not every key matches the widget url*/
    const getWidgetUtmsByUrl = async(url: string): Promise<Utms | null> => {
        const widgetPartnerDict: WidgetUtms = await $fetch('/api/widget-utms');
        const widgetKey: WidgetPartners | Widgets | null = keys(widgetPartnerDict)
            .find(key => widgetPartnerDict[key]?.url === url);
        return widgetPartnerDict?.[widgetKey] ?? null;
    };

    return {
        getWidgetUtmsByKey,
        getWidgetUtmsByUrl,
    };
}
